import { IndividualEmail } from '@/GeneratedTypes/IndividualEmail'

export function getEmptyIndividualEmail(seed?: IndividualEmailSeed): IndividualEmail {
  return {
    emailAddress: '',
    valid: 'true',
    validityReason: '',
    emailID: 0,
    priority: 1,
    typeEmailID: seed?.typeEmailID ?? humanEmailToEmailID('home'),
  }
}

export class IndividualEmailSeed {
  typeEmailID = humanEmailToEmailID('home')
}

export function blankHomeEmailSeed() {
  return new IndividualEmailSeed()
}

export function humanEmailToEmailID(s: string): string {
  switch (s.toLocaleLowerCase()) {
    case 'work':
      return 'WORK'
    default:
    case 'home':
    case 'personal':
      return 'PERSONAL'
  }
}
