import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { IndividualEmail } from '@/GeneratedTypes/IndividualEmail'
import { getEmptyIndividualEmail } from '@/lib/support/models/IndividualEmail/data'

/**
 * Grab the first email info on a contact
 * @param contact
 */
export function getFirstEmail(contact: PlayerContact | LeagueVolunteer): IndividualEmail {
  return contact?.emailAddresses?.[0] || getEmptyIndividualEmail()
}

/**
 * Sets an email structure on the contact, currently just the first,
 * logic can be adjusted later with a match/push strategy.
 * @param contact
 * @param email
 * @return {PlayerContact} updated with new email
 */
export function setFirstEmail<T extends PlayerContact | LeagueVolunteer>(
  contact: T,
  email: IndividualEmail
): T {
  if (contact?.emailAddresses?.length) {
    contact.emailAddresses.splice(0, 1, email)
  } else {
    contact.emailAddresses = [email]
  }
  return contact
}
