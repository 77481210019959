




































































import { defineComponent, ref, computed, watch, onMounted, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'
import { cloneDeep } from 'lodash'
import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import debounce from 'lodash/debounce'
import Loading from '@/elements/Loading.vue'
import { DataTableHeader } from 'vuetify/types'
import DataTable from '@/elements/DataTable/DataTable.vue'
import { Nav } from '@/elements/DataTable/KeyboardNavigationEnum'

/***
 * Runs a search box with a grid, updates on search pass to parent, and data gets fed back to
 * grid.
 * @vue-event {string} updateSearch - someone typed into the search button, update data with this event listener
 * @vue-property {GridColumn}
 */
export default defineComponent({
  name: 'SearchModal',
  components: {
    Loading,
    Modal,
    TextInput,
    DataTable,
  },
  props: {
    showSearchInput: { required: false, default: true },
    canCommitUnselected: { required: false, default: false },
    title: { type: String, required: true, default: '' },
    searchTerm: { type: String, required: false, default: '' },
    headers: { type: Array as PropType<DataTableHeader[]>, required: true, default: () => [] },
    itemKey: { type: String, required: false },
    data: { type: Array as PropType<Record<string | symbol, unknown>[]>, required: true, default: [] },
    loading: { type: Boolean, required: true, default: false },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)
    const currentSortOrder = ref<Record<string | symbol, unknown>[]>([])
    const selected = ref<Record<string | symbol, unknown>[]>([])
    const page = ref(1)
    const itemsPerPage = ref(10)
    const pageDirectionTracking = ref<Nav.PREV_PAGE | Nav.NEXT_PAGE | null>(null)
    const pageCount = ref(0)

    /***
     * term that is being typed into the box.
     */
    const searchInputTerm = ref('')

    // method defined on mount, uses a debounce function.
    const fireUpdateSearch = ref(() => {
      return
    })

    onMounted(() => {
      fireUpdateSearch.value = debounce(() => {
        ctx.emit('updateSearch', searchInputTerm.value)
      }, 500)
    })

    watch(
      () => props.searchTerm,
      () => {
        searchInputTerm.value = props.searchTerm
      },
      { immediate: true }
    )

    function rowDoubleClicked() {
      selectClick()
    }

    /***
     * set data and, emit final selected event,  close the dialog.
     */
    function emitSelected(r: Record<symbol | string, unknown> | null) {
      ctx.emit('onSelected', cloneDeep(r))
      closeModal()
    }

    function nextItem(navigate: Nav.PREV_ROW | Nav.NEXT_ROW | Nav.PAGE_BOTTOM | Nav.PAGE_TOP) {
      const data = currentSortOrder.value
      const bottom = currentSortOrder.value.length
      let nextIdx = -99

      if (navigate == Nav.PREV_ROW || navigate == Nav.NEXT_ROW) {
        nextIdx = nextRowIndex(navigate, data)
      }

      if (navigate == Nav.PAGE_BOTTOM) {
        selectLastRowOfPage(data)
      } else if (navigate == Nav.PAGE_TOP) {
        selectTopRowOfPage(data)
      } else if (nextIdx == bottom) {
        moveToNextPage()
      } else if (nextIdx == -1) {
        moveToPreviousPage()
      } else {
        moveToNextOrPrevRow(nextIdx, data)
      }
    }

    function nextRowIndex(navigate: Nav.PREV_ROW | Nav.NEXT_ROW, data: Record<symbol | string, unknown>[]) {
      let idx = -1
      let nextIdx = -99

      // If there is a selected item, find it in the array of data visible in the DataTable
      if (selected.value.length && props.itemKey) {
        idx = data.findIndex((s) => {
          return s[props.itemKey!] == selected.value[0][props.itemKey!]
        })
      }

      // Calculate the next index that needs to be selected
      if (navigate == Nav.NEXT_ROW || navigate == Nav.PREV_ROW) {
        nextIdx = navigate ? idx + navigate : -99
      }
      return nextIdx
    }

    function selectLastRowOfPage(data: Record<symbol | string, unknown>[]) {
      pageDirectionTracking.value = null
      selected.value = [data[data.length - 1]]
    }

    function selectTopRowOfPage(data: Record<symbol | string, unknown>[]) {
      pageDirectionTracking.value = null
      selected.value = [data[0]]
    }

    function moveToNextPage() {
      if (page.value == pageCount.value) return
      // move
      page.value = page.value + 1
      selected.value = []
      pageDirectionTracking.value = Nav.NEXT_PAGE
    }

    function moveToPreviousPage() {
      if (page.value == 1) return
      // move
      page.value = page.value - 1
      pageDirectionTracking.value = Nav.PREV_PAGE
    }

    function moveToNextOrPrevRow(nextIdx: number, data: Record<symbol | string, unknown>[]) {
      pageDirectionTracking.value = null
      selected.value = [data[nextIdx]]
    }

    function keyUp(action: KeyboardEvent) {
      if (action.keyCode == 38 || action.code == 'ArrowUp') {
        nextItem(Nav.PREV_ROW)
        return
      }

      if (action.keyCode == 40 || action.code == 'ArrowDown') {
        nextItem(Nav.NEXT_ROW)
        return
      }

      if (action.keyCode == 13 || action.code == 'Enter') {
        selectClick()
        return
      }
    }

    function selectClick() {
      // Check if we are in a submit state, UI should disable select button if no selected data.
      if (shouldSubmitShow.value) {
        emitSelected(selectedItem.value)
      }
    }

    function passiveSelected(r: Record<symbol | string, unknown>) {
      ctx.emit('passiveSelected', cloneDeep(r.item))
    }

    const selectedItem = computed(() => {
      if (selected.value.length > 0) {
        return selected.value[0]
      }
      return null
    })

    function cancel() {
      ctx.emit('cancel')
      closeModal()
    }

    const shouldSubmitShow = computed(() => {
      return selectedItem.value || props.canCommitUnselected
    })

    watch(
      () => searchInputTerm.value,
      () => {
        fireUpdateSearch.value()
      }
    )

    watch(
      () => props.data,
      () => {
        if (props.data.length > 0) {
          selected.value = [{ individualID: props.data[0].individualID }]
        }
      }
    )

    function setSelected(d: any[]) {
      selected.value = d
    }

    watch(
      () => isModalOpen.value,
      () => {
        selected.value = []
      }
    )

    function captureCurrentPageData(data: Record<string | symbol, unknown>[]) {
      /** 
        It's necessary to use the data returned in captureCurrentPageData when navigating using keyboard 
        strokes (as opposed to using this.data) to account for DataTable sorting and paging.
        However, when then page on the DataTable changes, it takes a beat for
        the new data to be available in this component so some navigation logic has to be done
        here when the new page data is recieved from the DataTable component.
      */
      currentSortOrder.value = cloneDeep(data)
      if (pageDirectionTracking.value == Nav.PREV_PAGE) {
        nextItem(Nav.PAGE_BOTTOM)
      } else if (pageDirectionTracking.value == Nav.NEXT_PAGE) {
        nextItem(Nav.PAGE_TOP)
      } else {
        nextItem(Nav.PAGE_TOP)
      }
    }

    function setPageCount(count: number) {
      pageCount.value = count
    }

    return {
      isModalOpen,
      searchInputTerm,
      keyUp,
      itemsPerPage,
      page,
      selected,
      setSelected,
      rowDoubleClicked,
      captureCurrentPageData,
      setPageCount,
      passiveSelected,
      cancel,
      shouldSubmitShow,
      selectClick,
    }
  },
})
