import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'
import { PhoneTypeEnum } from '@/lib/support/models/IndividualPhone/data'

const LOWER_NONCONFLICTING_NONZERO_PRIORITY_ADJUSTMENT = 1e4

/***
 * Iterate the phone numbers and set unique priorities, eliminate empty records and set types for
 * places where there is no type, try to maintain server-assigned ids where possible.
 * @param phonenumbers
 * @return clean phone numbers
 */
export function WeedThePhoneList(phonenumbers: IndividualPhone[]): IndividualPhone[] {
  const rv: IndividualPhone[] = []
  const dupphones: string[] = []
  phonenumbers.forEach((pn) => {
    // make sure we have a phone number and it isn't a duplicate
    if (pn.phoneNumber) {
      if (dupphones.indexOf(pn.phoneNumber) < 0) {
        dupphones.push(pn.phoneNumber)
        rv.push({
          ...pn,
          typePhoneID: !!pn.typePhoneID ? pn.typePhoneID : PhoneTypeEnum.MOBILE,
          // bubble mobile to the top in sort following this
          priority:
            pn.typePhoneID == PhoneTypeEnum.MOBILE
              ? getPhonePriority() - LOWER_NONCONFLICTING_NONZERO_PRIORITY_ADJUSTMENT
              : getPhonePriority(),
          phoneID: pn.phoneID < 0 ? 0 : pn.phoneID,
        })
      }
    }
  })

  let phonepriority = 1
  // dedup code above will force no priority is identical for sort
  // map will set phones in priority order, starting at 1
  return rv
    .sort((a, b) => (a.priority < b.priority ? -1 : 1))
    .map((x) => ({ ...x, priority: phonepriority++ }))
}

let PHONE_PRIORITY = 1e6

function getPhonePriority() {
  return ++PHONE_PRIORITY
}
